/*
 * @Author: DY
 * @Date: 2020-12-29 12:19:15
 * @LastEditTime: 2021-11-03 18:12:41
 * @Description: 
 * @Param: 
 * @FilePath: \JT.Web\src\assets\js\tableJS\YWShowLayer.js
 */
// 业务通用弹出层
export default {
    install(Vue) {
        // eslint-disable-next-line max-params
        Vue.prototype.$YWShowLayer = function (formParam, keyValue, btn, type) {
            this.$toast({
                width: '80%',
                type: 'eject',
                title: true,
                t_url: 'popUpForm/index',
                extr: {
                    closeBtn: 'width: 0.8rem; height: 0.8rem;',
                    code: {
                        FormUrl: formParam,
                        FormKeyValue: keyValue,
                        ThreeBtnDescribe: btn,
                        FormUseInJyFlow: false,
                        type,
                    },
                },
                viewPosition: 'components',
            });
        };

        /*
         * 流程表单查看[弹出多表单+附件]
         * contract_number: 合同编号
         * viewScene: 视图场景【打开方式】
        */
        Vue.prototype.$processFormView = function (contract_number, viewScene) {
            this.$toast({
                width: '80%',
                type: 'eject',
                title: true,
                text: '合同查看',
                t_url: 'popUpForm/index_see',
                extr: {
                    code: {
                        contractNumber: contract_number,
                        viewScene: viewScene,
                    },
                },
                viewPosition: 'components',
            });
        };

        /*
         * 流程表单查看[弹出多表单+附件]
         * 合同台账合并后的，新增，维护弹窗
         * contract_number: 合同编号
         * viewScene: 视图场景【打开方式】
        */
        Vue.prototype.$processStageFormView = function (contract_number, viewScene, cvid) {
            this.$toast({
                width: '80%',
                type: 'eject',
                title: true,
                text: '合同查看',
                t_url: 'popUpForm/index_stb',
                extr: {
                    code: {
                        contractNumber: contract_number ? contract_number : '',
                        cvid: cvid ? cvid : null,
                        viewScene: viewScene,
                    },
                },
                viewPosition: 'components',
            });
        };
    },
};