/*
 * @Author: your name
 * @Date: 2020-03-21 15:47:03
 * @LastEditTime: 2022-07-13 14:20:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BusinessPlatform\src\services\api.js
 */
import axios from 'axios';
import '@/store/index';
import { Message } from 'element-ui';
import Vue from 'vue';
import router from '@/router';
import { resetTokenAndClearUser } from '@/utils';
Vue.prototype.$axios = axios;
let isRefreshToken = false;
axios.interceptors.request.use(config => {
    let token = null;
    let ContentType = '';
    if (config.url.indexOf('SwitchTenant') < 0) {
        token = window.sessionStorage.getItem('token');
    } else {
        token = window.sessionStorage.getItem('refreshToken');
    }
    if (config.headers?.ContentType) {
        ContentType = config.headers.ContentType;
    } else {
        ContentType = 'application/json';
    }
    config.headers = {
        Accept: 'application/json',
        Authorization: token,
        'Content-Type': ContentType,
    };

    config.withCredentials = true;
    if (config.method === 'post') {
        const curPost = config.url.split('/')[config.url.split('/').length - 1];
        if (curPost === 'uploadpicture' || curPost === 'uploadfile') {
            config.headers = {
                Authorization: token,
                'Content-type': 'multipart/form-data',
            };
            return config; // 这里对上传文件/图片的 api 不做传参序列化处理
        }
    }
    return config;
});

axios.interceptors.response.use(
    res => {
        const headers = res.headers;
        if (['application/octet-stream'].indexOf(headers['content-type']) > -1 || res.request.responseType === 'arraybuffer') {
            return res;
        }
        const data = res.data;
        if (data) {
            if (data.result === 1 || data.ErrorCode?.Code === '0') {
                return data.DataContext;
            }
            if (data.ErrorCode && data.ErrorCode.Code === '-1') {
                throw data;
            } else if (res.status === 200 && !data.DataContext) {
                return data;
            }
        }
        throw data;
    },
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    if (!isRefreshToken) {
                        isRefreshToken = true;
                        // 返回 401 清除token信息并跳转到登录页面
                        // sessionStorage.setItem('token', window.sessionStorage.getItem('refreshToken'));
                        const refreshToken = window.sessionStorage.getItem('refreshToken');
                        return axios.post('/interfaceApi/login/refresh_token', {
                            refresh_token: refreshToken,
                        }, {
                            headers: {
                                ContentType: 'application/x-www-form-urlencoded',
                            },
                        }).then(res => {
                            sessionStorage.setItem('token', `${res.token_type} ${res.access_token}`);
                            sessionStorage.setItem('refreshToken', `${res.token_type} ${res.refresh_token}`);
                            return axios.request(error.config);
                        }).catch(() => {
                            resetTokenAndClearUser();
                            router.replace({ name: 'login' });
                            Message({
                                showClose: true,
                                message: '登录异常，请重新登录!',
                                type: 'error',
                            });
                        });
                    }
                    Message({
                        showClose: true,
                        message: '登录状态已过期，请重新登录!',
                        type: 'error',
                    });
                    resetTokenAndClearUser();
                    router.replace({ name: 'login' });
            }
        }
        return Promise.reject(error.response.data); // 返回接口返回的错误信息
    }
);
// eslint-disable-next-line max-params
function send(url, method, body, options, defEx) {
    const opts = { ...options };
    let token = null;
    if (url.indexOf('SwitchTenant') < 0) {
        token = window.sessionStorage.getItem('token');
    } else {
        token = window.sessionStorage.getItem('refreshToken');
    }
    opts.headers = {
        Accept: 'application/json',
        Authorization: token,
        'Conteent-Type': 'application/json',
        ...opts.headers,
    };
    return axios({ method, url, data: body, ...opts })
        .then(res => {
            return res;
        }).catch(error => {
            const msg = error.ErrorCode.Message;
            // 接口异常处理
            if (defEx) {
            // 通用异常处理
                Message({
                    showClose: true,
                    message: msg,
                    type: 'error',
                });
            } else {
            // 返回前台处理
                return Promise.reject(error);
            }
        });
}
Vue.prototype.$axios.$get = (url, { options = null, defEx = false } = {}) => {
    return send(url, 'get', null, options, defEx);
};
Vue.prototype.$axios.$post = (url, body, { options = null, defEx = false } = {}) => {
    return send(url, 'post', body, options, defEx);
};
Vue.prototype.$axios.$put = (url, body, { options = null, defEx = false } = {}) => {
    return send(url, 'put', body, options, defEx);
};
Vue.prototype.$axios.$delete = (url, body, { options = null, defEx = false } = {}) => {
    return send(url, 'delete', body, options, defEx);
};