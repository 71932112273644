/*
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 * 
 * 
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * 
 *            佛祖保佑       永不宕机     永无BUG
 * 
 * @Author: DY
 * @Date: 2021-06-25 15:38:46
 * @LastEditTime: 2022-03-16 11:15:59
 * @Description: 通用弹出组件
 * @Param: 
 *          text：      头部文本
 *          title：     是否显示头部文本
 *          footer：    是否显示底部按钮区
 *          type：      打开类型（eject弹出、sideslip侧滑）
 *          theme：     主题
 *          style：     弹出样式（宽，高）
 *          layerView： 打开页面路由地址
 *          extr：      附加参数、回调
 * @FilePath: \JT_Web_test\src\components\layer\index.js
 */

import store from '@/store';
import vue from 'vue';
import toastComponent from './index.vue';

// 流程进行时
// 组件构造器，构造出一个 vue组件实例
const ToastConstructor = vue.extend(toastComponent);
// eslint-disable-next-line max-len
function showToast({ text, title = false, footer = false, type, width = '80%', height, style, t_url, extr = {}, viewPosition, showlayerHeadBtn = false }) {
    let mycomponent = null;
    if (viewPosition === 'components') {
        mycomponent = () => import(`@/components/${t_url}.vue`);
    } else {
        mycomponent = () => import(`@/views/${t_url}.vue`);
    }
    const toastDom = new ToastConstructor({
        el: document.createElement('div'),
        data() {
            return {
                text: text,
                title: title,
                footer: footer,
                type: type,
                style: {
                    width: width,
                    height: height,
                },
                layerView: mycomponent,
                extr: extr,
                showPopup: true,
                layerLoading: false,

                // 显示缩放按钮
                showlayerHeadBtn: showlayerHeadBtn,
                // 弹出组件缩放类名
                layerfullScreenClass: null,
            };
        },
        created() {
            Object.assign(this.style, style);
        },
        computed: {},
        mounted() {
            // 转发后台配置方法，关闭弹出层
            window.hideToast = this.hide;
            // 转发后台配置方法，刷新列表组件
            window.hackReset = this.hackReset;
        },
        methods: {
            // 隐藏侧边栏,向上派发事件(向父组件传值)
            hide() {
                if (this.extr && this.extr.closeCallback) {
                    this.extr.closeCallback();
                }
                toastDom.$el.remove();
                this.showPopup = false;
            },
            // 刷新所有已打开列表组件
            hackReset() {
                store.state.currentOpenList.forEach(item => {
                    item.tableObj.updateTable();
                });
            },
            loadingOpen() {
                this.layerLoading = true;
            },
            loadingClose() {
                this.layerLoading = false;
            },
            // 弹出框全屏
            layerFullScreen() {
                this.layerfullScreenClass = 'fullScreenStyle';
            },
            // 弹出框全屏
            layerExitScreen() {
                this.layerfullScreenClass = null;
            },
        },
        components: {
            mycomponent,
        },
    });
    toastDom.vm = toastDom.$mount();
    // 添加节点
    if (document.getElementById('app')) {
        document.getElementById('app').append(toastDom.vm.$el);
    } else {
        this.$('body').append(toastDom.vm.$el);
    }
    return toastDom;
}
// 全局注册
function registryToast() {
    vue.prototype.$toast = showToast;
}
export default registryToast;