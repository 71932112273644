/*
 * @Author: DY
 * @Date: 2021-04-08 15:31:14
 * @LastEditTime: 2021-04-28 12:23:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT.Web_gx\src\assets\js\queryString\index.js
 */
export default {
    install(Vue) {
        // eslint-disable-next-line consistent-return
        Vue.prototype.$queryString = function (requestUrl, item) {
            if (requestUrl && requestUrl.length > 0) {
                const querys = requestUrl.split('&');
                const result = {};
                for (let i = 0; i < querys.length; i++) {
                    const temp = querys[i].split('=');
                    if (temp.length < 2) {
                        result[temp[0]] = '';
                    } else {
                        result[temp[0]] = temp[1];
                    }
                }
                if (item) {
                    return result[item];
                }
                return result;
            }
        };
    },
};