/*
 * @Author: your name
 * @Date: 2021-04-08 14:55:51
 * @LastEditTime: 2021-09-22 17:40:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT.Web\src\assets\js\tableJS\WFShowPhaseLayer.js
 */
// 流程在办
export default {
    install(Vue) {
        Vue.prototype.$WFShowPhaseLayer = function (businessInstanceId, businessPhaseId) {
            this.$toast({
                width: '90%',
                t_url: 'businessInstance/index',
                extr: {
                    code: {
                        businessInstanceUrl: 'simpleflow/processes/' + businessInstanceId + '/' + businessPhaseId,
                        businessInstanceId: businessInstanceId,
                        FormUseInJyFlow: true,
                    },
                },
                viewPosition: 'components',
            });
        };
    },
};