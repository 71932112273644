/*
 * @Author: DY
 * @Date: 2021-06-01 10:22:28
 * @LastEditTime: 2021-11-04 15:51:25
 * @Description: 
 * @Param: 
 * @FilePath: \JT.Web\src\assets\js\tableJS\HsAndHdLayer.js
 */
// 业务通用弹出层
export default {
    install(Vue) {
        // eslint-disable-next-line max-params
        Vue.prototype.$HsAndHdLayer = function (formParam, keyValue, btnDescribe, btnStatus) {
            this.$toast({
                width: '80%',
                type: 'eject',
                title: true,
                t_url: 'accountingManagement/popUpForm/index',
                extr: {
                    closeBtn: 'width: 0.8rem; height: 0.8rem;',
                    code: {
                        FormUrl: formParam,
                        FormKeyValue: keyValue,
                        ThreeBtnDescribe: btnDescribe,
                        BtnStatus: btnStatus,
                        FormUseInJyFlow: false,
                    },
                },
                viewPosition: 'views',
            });
        };
        Vue.prototype.$ShowManyButtonForm = function (formParam, keyValue, btnList) {
            this.$toast({
                width: '80%',
                type: 'eject',
                title: true,
                t_url: 'accountingManagement/popUpForm/formIndex',
                extr: {
                    closeBtn: 'width: 0.8rem; height: 0.8rem;',
                    code: {
                        FormUrl: formParam,
                        FormKeyValue: keyValue,
                        btnList: btnList,
                        FormUseInJyFlow: false,
                    },
                },
                viewPosition: 'views',
            });
        };

        /*
         * 全局自定义按钮弹出表单
         * formParam: 表单code
         * keyValue: 表单编号
         * saveCallback: 保存成功调用
         * btnList: 自定义按钮组
        */
        // eslint-disable-next-line max-params
        Vue.prototype.$customButtonForm = function (formParam, keyValue, saveCallback, btnList) {
            this.$toast({
                width: '80%',
                type: 'eject',
                title: true,
                t_url: 'accountingManagement/popUpForm/customBtnForm',
                extr: {
                    closeBtn: 'width: 0.8rem; height: 0.8rem;',
                    code: {
                        FormUrl: formParam,
                        FormKeyValue: keyValue,
                        saveCallback: saveCallback,
                        btnList: btnList,
                    },
                },
                viewPosition: 'views',
            });
        };
    },
};
