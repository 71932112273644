/**
 * @ Author: DY
 * @ Date: 2022-06-22 15:05:28
 * @ LastEditTime: 2022-09-21 16:00:49
 * @ Description: 
 * @ FilePath: \BusinessPlatform\src\router\index.js
 */
import Vue from 'vue';
import Router from 'vue-router';
import menuJson from '@/router/menuJson';

Vue.use(Router);

const createRouter = () =>
    new Router({
        routes: menuJson.commonRoutes,
        mode: 'history',
    });

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher;
}

export default router;
