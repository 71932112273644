
// 取最后一个字符
const getLastCharacter = value => {
    let str = '';
    if (value) {
        value = value + '';
        str = value.substr(value.length - 1, 1);
    }
    return str;
};
export {
    getLastCharacter,
};