<!--
 * @Author: your name
 * @Date: 2021-04-08 14:55:51
 * @LastEditTime: 2021-05-18 09:58:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test_gx\src\components\dialog\dialog.vue
-->
<template>
    <div class="cdialog" v-if="visible">
        <el-dialog
            custom-class="cdialog_el-dialog"
            :title="title"
            :visible.sync="dialog.visible"
            @close="_close"
            :width="width"
            :modal="true"
            :center="true"
            :close-on-click-modal="false"
            :lock-scroll="true"
            :append-to-body="true"
            top="5vh"
        >
            <slot name="body"></slot>
            <!-- <div slot="footer" class="dialog-footer">
                <div slot="btns" align="center">
                    <slot name="dialogBtns">
                        <el-button type="default" size="small" @click="_close">
                            {{ !options.cancelBtn?'取消':options.cancelBtn }}
                        </el-button>
                        <el-button type="primary" size="small" @click="options.confirm">
                            {{ !options.sureBtn?'确定':options.sureBtn }}
                        </el-button>
                    </slot>
                </div>
            </div> -->
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'cdialog',
    data() {
        return {
            dialog: {
                visible: true,
            },
            extr: {},
        };
    },
    watch: {
        visible: {
            handler(val) {
                this.dialog.visible = val;
            },
            deep: true,
        },
    },
    created() {},
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        title: {
            type: String,
            default() {
                return '';
            },
        },
        options: {
            type: Object,
            default() {
                return {};
            },
        },
        width: {
            type: String,
            default() {
                return '60%';
            },
        },
    },
    methods: {
        _close() {
            this.$emit('close');
        },
    },
};
</script>
<style>
    .cdialog_el-dialog{
        height: 90%;
    }
</style>
