/*
 * @Author: your name
 * @Date: 2020-05-18 16:50:58
 * @LastEditTime: 2021-07-20 18:18:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT.Web\src\promission.js
 */ 
import router from './router';
import { reAddRouters } from './utils';

// 是否有菜单数据
let hasMenus = false;
router.beforeEach(async (to, from, next) => {
    if (sessionStorage.getItem('token')) {
        if (to.path === '/login') {
            next({ path: '/' });
        } else if (hasMenus) {
            next();
        } else {
            reAddRouters();
            hasMenus = true;
            next({ path: to.path || '/' });
        }
    } else {
        hasMenus = false;
        if (to.path === '/login' || to.path.includes('/sso/yunzhijia') || to.path === '/ios/privacy') {
            next();
        } else {
            next('/login');
        }
    }
});