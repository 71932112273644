const QueryString = function (item, requestUrl) {
    const querys = requestUrl.split('&');
    const result = {};
    for (let i = 0; i < querys.length; i++) {
        const temp = querys[i].split('=');
        if (temp.length < 2) {
            result[temp[0]] = '';
        } else {
            result[temp[0]] = temp[1];
        }
    }
    return result[item];
};
export default QueryString;