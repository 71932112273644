/* eslint-disable */
var CreatedOKLodop7766 = null;
//====判断是否需要安装CLodop云打印服务器:====
export function needCLodop() {
	return true;
}

//====页面引用CLodop云打印必须的JS文件：====
export function initCLodop() {
	if (needCLodop()) {
		var head = document.head || document.getElementsByTagName("head")[0] || document.documentElement;
		var oscript = document.createElement("script");
		oscript.src = "http://localhost:8000/CLodopfuncs.js?priority=2";
		head.insertBefore(oscript, head.firstChild);

		//引用双端口(8000和18000）避免其中某个被占用：
		oscript = document.createElement("script");
		oscript.src = "http://localhost:18000/CLodopfuncs.js?priority=1";
		head.insertBefore(oscript, head.firstChild);
	};
}

//====获取LODOP对象的主过程：====
export function getLodop(_that, oOBJECT, oEMBED) {
	var strHtmInstall = "<font color='#FF00FF'>打印控件未安装!点击这里<a href='/toolkit/print/install_lodop32.zip' target='_self'>执行安装</a>,安装后请刷新页面或重新进入。</font>";
	var strHtmUpdate = "<font color='#FF00FF'>打印控件需要升级!点击这里<a href='/toolkit/print/install_lodop32.zip' target='_self'>执行升级</a>,升级后请重新进入。</font>";
	var strHtm64_Install = "<font color='#FF00FF'>打印控件未安装!点击这里<a href='/toolkit/print/install_lodop64.zip' target='_self'>执行安装</a>,安装后请刷新页面或重新进入。</font>";
	var strHtm64_Update = "<font color='#FF00FF'>打印控件需要升级!点击这里<a href='/toolkit/print/install_lodop64.zip' target='_self'>执行升级</a>,升级后请重新进入。</font>";
	var strHtmFireFox = "<font color='#FF00FF'>（注意：如曾安装过Lodop旧版附件npActiveXPLugin,请在【工具】->【附加组件】->【扩展】中先卸它）</font>";
	var strHtmChrome = "<font color='#FF00FF'>(如果此前正常，仅因浏览器升级或重安装而出问题，需重新执行以上安装）</font>";
	var strCLodopInstall = "<font color='#FF00FF'>打印服务(本地)未安装启动!点击这里<a href='/toolkit/print/CLodop_Setup_for_Win32NT.zip' target='_self'>执行安装</a>,安装后请刷新页面。</font>";
	var strCLodopUpdate = "<font color='#FF00FF'>打印服务需升级!点击这里<a href='/toolkit/print/CLodop_Setup_for_Win32NT.zip' target='_self'>执行升级</a>,升级后请刷新页面。</font>";
	var LODOP;
	try {
		var isIE = (navigator.userAgent.indexOf('MSIE') >= 0) || (navigator.userAgent.indexOf('Trident') >= 0);
		if (needCLodop()) {
			try { LODOP = getCLodop(); } catch (err) { };
			if (!LODOP && document.readyState !== "complete") {
				_that.$message({
					type: 'info',
					message: '打印组件没准备好，请稍后再试！',
					dangerouslyUseHTMLString: true,
				});
				return;
			};
			if (!LODOP) {
				_that.$message({
					type: 'info',
					message: strCLodopInstall,
					dangerouslyUseHTMLString: true,
				});
				return;
			} else {
				if (CLODOP.CVERSION < "3.0.0.2") {
					_that.$message({
						type: 'info',
						message: strCLodopUpdate,
						dangerouslyUseHTMLString: true,
					});
				};
				if (oEMBED && oEMBED.parentNode) oEMBED.parentNode.removeChild(oEMBED);
				if (oOBJECT && oOBJECT.parentNode) oOBJECT.parentNode.removeChild(oOBJECT);
			};
		} else {
			var is64IE = isIE && (navigator.userAgent.indexOf('x64') >= 0);
			//=====如果页面有Lodop就直接使用，没有则新建:==========
			if (oOBJECT != undefined || oEMBED != undefined) {
				if (isIE) LODOP = oOBJECT; else LODOP = oEMBED;
			} else if (CreatedOKLodop7766 == null) {
				LODOP = document.createElement("object");
				LODOP.setAttribute("width", 0);
				LODOP.setAttribute("height", 0);
				LODOP.setAttribute("style", "position:absolute;left:0px;top:-100px;width:0px;height:0px;");
				if (isIE) LODOP.setAttribute("classid", "clsid:2105C259-1E0C-4534-8141-A753534CB4CA");
				else LODOP.setAttribute("type", "application/x-print-lodop");
				document.documentElement.appendChild(LODOP);
				CreatedOKLodop7766 = LODOP;
			} else LODOP = CreatedOKLodop7766;
			//=====Lodop插件未安装时提示下载地址:==========
			if ((LODOP == null) || (typeof (LODOP.VERSION) == "undefined")) {
				if (navigator.userAgent.indexOf('Chrome') >= 0)
					_that.$message({
						type: 'info',
						message: strHtmChrome,
						dangerouslyUseHTMLString: true,
					});
				if (navigator.userAgent.indexOf('Firefox') >= 0)
					_that.$message({
						type: 'info',
						message: strHtmFireFox,
						dangerouslyUseHTMLString: true,
					});
				if (is64IE) {
					_that.$message({
						type: 'info',
						message: strHtm64_Install,
						dangerouslyUseHTMLString: true,
					});
				} else {
					_that.$message({
						type: 'info',
						message: strHtmInstall,
						dangerouslyUseHTMLString: true,
					});
				}
				return LODOP;
			};
		};
		if (LODOP.VERSION < "6.0") {
			if (!needCLodop()) {
				if (is64IE) {
					_that.$message({
						type: 'info',
						message: strHtm64_Install,
						dangerouslyUseHTMLString: true,
					});
				} else {
					_that.$message({
						type: 'info',
						message: strHtmInstall,
						dangerouslyUseHTMLString: true,
					});
				}
			};
			return LODOP;
		};
		//===如下空白位置适合调用统一功能(如注册语句、语言选择等):===
		//LODOP.SET_LICENSES("北京XXXXX公司","8xxxxxxxxxxxxx5","","");

		//===========================================================
		return LODOP;
	} catch (err) {
		_that.$message({
			type: 'info',
			message: 'getLodop出错:' + err,
			dangerouslyUseHTMLString: true,
		});
	};
};