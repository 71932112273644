import { resetRouter } from '../router';
import router from './../router';
import store from '@/store';
import axios from 'axios';
import menuJson from '@/router/menuJson';

function test(getRouter, parent) {// 根据菜单编码生成 path  /home
    if (parent && parent.url) {
        getRouter.component = getRouter.path;
        getRouter.url = parent.url + '/' + getRouter.code;
        getRouter.path = getRouter.url;
        getRouter.meta = {
            title: getRouter.name,
        };
        getRouter.name = null;
    } else {
        getRouter.component = getRouter.path;
        getRouter.url = '/' + getRouter.code;
        getRouter.path = getRouter.url;
        getRouter.meta = {
            title: getRouter.name,
        };
        getRouter.name = null;
    }
    if ((getRouter.children || []).length > 0) {
        for (let i = 0; i < getRouter.children.length; i++) {
            getRouter.children[i] = test(getRouter.children[i], getRouter);
        }
    }
    return getRouter;
}

function handleRouters(data) {
    let newData = [];
    if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            if ((data[i].children || []).length > 0) {
                newData = newData.concat(handleRouters(data[i].children));
            }
            newData.push(data[i]);
        }
    }

    return newData;
}

function filterAsyncRouter(asyncRouterMap) {
    const accessedRouters = asyncRouterMap.filter(route => {
        if (route.component) {
            const component = route.component;
            if (route.type === '4') {
                const newRoute = route.component.split('?');
                route.component = () => import('@/views/' + newRoute[0] + '.vue');
                if (newRoute[1]) {
                    route.meta.TableCode = newRoute[1];
                    route.name = null;
                }
            } else {
                route.meta.iframeSrc = component;
                route.component = () => import('@/views/template/index.vue');
            }
        } else {
            route.component = () => import('@/views/default/construction.vue');
        }
        return true;
    });

    return accessedRouters;
}

// 将菜单信息转成对应的路由信息 动态添加
export function menusToRoutes(data) {
    const result = [];
    const children = [];

    result.push({
        path: '/home',
        component: () => import('../views/index.vue'),
        children,
    });

    if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            data[i] = test(data[i]);
        }
    }

    store.commit('setMenuData', data);
    data = handleRouters(data);
    if (data.length > 0) {
        result[0].redirect = data[0].path;
    }

    data.forEach(item => {
        children.push(item);
    });
    data = filterAsyncRouter(data); // 过滤路由

    children.push(...menuJson.settingsMenu);

    // 最后添加404页面 否则会在登陆成功后跳到404页面
    result.push({ path: '*', redirect: '/error' });
    // 保存路由数据
    store.commit('setRoutes', result);
    return result;
}

// 切换用户
export async function switchTokenAndClearUser() {
    // 重置路由
    resetRouter();

    // 请求新用户菜单数据
    const data = await axios.get('/interfaceApi/basicplatform/usermenu?code=' + localStorage.getItem('menuCode'));
    let routes;
    // 判断有用户，没有菜单数据，显示无授权页
    if (data.userMenus.length < 1) {
        routes = menusToRoutes([{
            id: '',
            code: 'zwsq',
            name: '',
            icon: '',
            path: 'default/noAuthorization',
            type: '4',
        }]);
    } else {
        // 处理菜单数据
        routes = menusToRoutes(data.userMenus[0].children);
    }
    // 动态添加路由
    router.addRoutes(routes);
}

// 重新添加路由信息
export async function reAddRouters() {
    // 重置路由
    resetRouter();
    const data = await axios.get('/interfaceApi/basicplatform/usermenu?code=' + localStorage.getItem('menuCode'));
    let routes;
    // 判断有用户，没有菜单数据，显示无授权页
    if (data.userMenus.length < 1) {
        routes = menusToRoutes([{
            id: '',
            code: 'zwsq',
            name: '',
            icon: '',
            path: 'default/noAuthorization',
            type: '4',
        }]);
    } else {
        routes = menusToRoutes(data.userMenus[0].children);
    }
    // 动态添加路由
    router.addRoutes(routes);
}

// 退出登陆
export function resetTokenAndClearUser() {
    // 退出登陆 清除用户资料
    window.sessionStorage.removeItem('token');
    window.sessionStorage.removeItem('refreshToken');
    window.sessionStorage.removeItem('clientID');
    window.sessionStorage.removeItem('loginType');
    // 重设路由
    resetRouter();
}
