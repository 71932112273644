/*
 * @Author: DY
 * @Date: 2022-02-22 18:21:10
 * @LastEditTime: 2022-02-28 14:57:00
 * @Description: 
 * @Param: 
 * @FilePath: \JT_Web_test\src\assets\js\print\PrintPreview.js
 */
import { initCLodop, getLodop } from './LodopFuncs';

export default {
    install(Vue) {
        /*
         * 表单打印【单页】
        */
        Vue.prototype.$printPreview = function (printCode, dataItem) {
            initCLodop();
            this.$confirm('是否打印', '确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post('/interfaceApi/basicplatform/print/generate_print_template', {
                        printCode: printCode,
                        printFields: dataItem,
                    })
                    .then(res => {
                        if (res) {
                            const LODOP = getLodop(this);
                            if (LODOP) {
                                // eslint-disable-next-line no-eval
                                eval(res);
                                LODOP.PREVIEW();
                            }
                        }
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消打印',
                });
            });
        };
        /*
         * 表单打印【多页】
        */
        Vue.prototype.$printPreview_multiPage = function (formData) {
            initCLodop();
            this.$axios
                .post('/interfaceApi/basicplatform/print/batch_generate_print_template', {
                    templateInputs: formData,
                })
                .then(res => {
                    if (res) {
                        const listData = res.printdata;
                        const LODOP = getLodop(this);
                        if (LODOP) {
                            // eslint-disable-next-line no-eval
                            eval(res.norms);
                            for (let i = 0; i < listData.length; i++) {
                                // eslint-disable-next-line no-eval
                                eval(listData[i]);
                                LODOP.NewPageA();
                            }
                            LODOP.PREVIEW();
                        }
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        };
    },
};


