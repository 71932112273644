/**
 * @ Author: DY
 * @ Date: 2023-01-06 17:09:28
 * @ LastEditTime: 2023-02-03 18:04:40
 * @ Description: 
 * @ FilePath: \BusinessPlatform\src\assets\js\currencyFun\globalMountFun.js
 */
/*
 * @Author: DY
 * @Date: 2022-06-28 11:47:47
 * @LastEditTime: 2022-06-29 15:15:34
 * @Description: 全局挂载方法
 * @Param: 
 * @FilePath: \BusinessPlatform\src\assets\js\currencyFun\globalMountFun.js
 */

export default {
    install(Vue) {
        /**
         * 获取登录用户信息
         * fieldName        返回字段名称
         */
        Vue.prototype.$takeTokenParameters = function (fieldName) {
            const info = sessionStorage.getItem('userInfo');
            const userInfo = JSON.parse(decodeURI(info));
            let callbackData = null;
            if (fieldName) {
                if (userInfo[fieldName]) {
                    callbackData = userInfo[fieldName];
                } else {
                    // this.$message({
                    //     showClose: true,
                    //     message: `没有查询到用户【${fieldName}】数据！`,
                    //     type: 'error',
                    // });
                    console.log(`没有查询到用户【${fieldName}】数据！`);
                }
            } else {
                callbackData = userInfo;
            }
            return callbackData;
        };
        /**
         * 获取地址栏参数
         * requestUrl       地址
         * item             参数名称
         */
        Vue.prototype.$queryString = function (requestUrl, item) {
            let callbackData = null;
            if (requestUrl && requestUrl.length > 0) {
                const querys = requestUrl.split('&');
                const result = {};
                for (let i = 0; i < querys.length; i++) {
                    const temp = querys[i].split('=');
                    if (temp.length < 2) {
                        result[temp[0]] = '';
                    } else {
                        result[temp[0]] = temp[1];
                    }
                }
                if (item) {
                    callbackData = result[item];
                } else {
                    callbackData = result;
                }
            }
            return callbackData;
        };
    },
};
