/*
 * @Author: DY
 * @Date: 2021-04-08 14:55:51
 * @LastEditTime: 2021-09-22 17:40:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT.Web\src\assets\js\tableJS\WFShowStartLayer.js
 */
/* 插件名：流程启动时
 * configBusinessCode： 流程编码
 * term：自定义参数
 * _this：当前打开流程对象。仅用于刷新打开流程的列表.
 */
export default {
    install(Vue) {
        Vue.prototype.$WFShowStartLayer = function (configBusinessCode, term, _this) {
            this.$toast({
                width: '90%',
                t_url: 'businessInstance/index',
                extr: {
                    code: {
                        businessInstanceUrl: '/simpleflow/businessinstance/templateinfo/' + configBusinessCode,
                        configBusinessCode: configBusinessCode,
                        FormUseInJyFlow: true,
                        term: term,
                        procedure: true,
                        obj: _this,
                    },
                },
                viewPosition: 'components',
            });
        };
    },
};