/*
 * @Author: DY
 * @Date: 2021-12-09 10:29:51
 * @LastEditTime: 2022-02-08 18:32:22
 * @Description: 
 * @Param: 
 * @FilePath: \JT_Web_test\src\constants\File.constant.js
 */
const FILE_BASE_URL = () => {
    let fileUrl = '';
    const hostUrl = window.location;
    if (hostUrl.host === 'www.concrete-cloud.com') {
        fileUrl = `${hostUrl.protocol}//files.concrete-cloud.com/`;
    } else {
        fileUrl = `${hostUrl.protocol}//temp-files.concrete-cloud.com/`;
    }
    return fileUrl;
};
// 配置表单获取文件服务地址
const FILE_BASE_URL_OSS = () => {
    let fileUrl = '';
    const hostUrl = window.location;
    if (hostUrl.host === 'www.concrete-cloud.com') {
        fileUrl = `${hostUrl.protocol}//files.concrete-cloud.com/`;
    } else {
        fileUrl = `${hostUrl.protocol}//temp-files.concrete-cloud.com/`;
    }
    return fileUrl;
};
export default {
    FILE_BASE_URL,
    FILE_BASE_URL_OSS,
};

