<!--
 * @Author: DY
 * @Date: 2021-12-09 10:29:50
 * @LastEditTime: 2022-01-04 10:23:17
 * @Description:
 * @Param:
 * @FilePath: \JT_Web_test\src\App.vue
-->
<template>
    <router-view></router-view>
</template>
<script>
export default {
    name: 'app',
    data() {
        return {};
    },
    components: {},
};
</script>

<style lang="stylus">
#app
    height 100%
</style>
