/*
 * @Author: DY
 * @Date: 2020-08-15 14:27:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-14 10:52:53
 * @Describe: 
 */
// 业务通用弹出层
export default {
    install(Vue) {
        Vue.prototype.$YWShowMultipleForms = function (forms, width = '80%') {
            this.$toast({
                width: width,
                type: 'eject',
                title: true,
                t_url: 'multipleForms/index',
                extr: {
                    closeBtn: 'width: 0.8rem; height: 0.8rem;',
                    formList: forms,
                },
                viewPosition: 'components',
            });
        };
    },
};