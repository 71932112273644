/**
 * @                        _oo0oo_
 * @                       o8888888o
 * @                       88" . "88
 * @                       (| -_- |)
 * @                       0\  =  /0
 * @                     ___/`---'\___
 * @                   .' \\|     |// '.
 * @                  / \\|||  :  |||// \
 * @                 / _||||| -:- |||||- \
 * @                |   | \\\  - /// |   |
 * @                | \_|  ''\---/''  |_/ |
 * @                \  .-\__  '-'  ___/-. /
 * @              ___'. .'  /--.--\  `. .'___
 * @           ."" '<  `.___\_<|>_/___.' >' "".
 * @          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 * @          \  \ `_.   \_ __\ /__ _/   .-` /  /
 * @      =====`-.____`.___ \_____/___.-`___.-'=====
 * @                        `=---='
 * @
 * @
 * @      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * @
 * @            佛祖保佑     永不宕机     永无BUG
 * @
 * @ Author: DY
 * @ Date: 2022-08-29 17:33:12
 * @ LastEditTime: 2022-09-21 17:36:24
 * @ Description: 菜单数据
 * @ FilePath: \BusinessPlatform\src\router\menuJson.js
 */


const menuJson = {
    commonRoutes: [
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/login/index'),
        }, {
            path: '/',
            redirect: '/home',
        }, {
            path: '/ios/privacy',
            name: 'privacy',
            component: () => import('@/views/ios/privacy.vue'),
        }, {
            // 集团运营区色块页
            path: '/EBCHome',
            name: 'EBCHome',
            component: () => import('@/views/EBCHome/index.vue'),
        }, {
            // 统计分析
            path: '/statisticalAnalysis',
            name: 'statisticalAnalysis',
            component: () => import('@/views/EBCHome/statisticalAnalysis/index.vue'),
        }, {
            // 三维场站
            path: '/threeDimensional',
            name: 'threeDimensional',
            component: () => import('@/views/EBCHome/threeDimensional/index.vue'),
        }, {
            // 知识图谱
            path: '/knowledgeGraph',
            name: 'knowledgeGraph',
            component: () => import('@/views/EBCHome/knowledgeGraph/index.vue'),
        }, {
            // BI分析
            path: '/BIAnalysis',
            name: 'BIAnalysis',
            component: () => import('@/views/EBCHome/BIAnalysis/index.vue'),
        }, {
            // 综合管控
            path: '/comprehensiveControl',
            name: 'comprehensiveControl',
            component: () => import('@/views/EBCHome/comprehensiveControl/index.vue'),
        }, {
            // 每日数据
            path: '/dailyData',
            name: 'dailyData',
            component: () => import('@/views/EBCHome/dailyData/index.vue'),
        }, {
            // 领导驾驶舱
            path: '/leadingCockpit',
            name: 'leadingCockpit',
            component: () => import('@/views/leadingCockpit/index.vue'),
        }, {
            // 企业大屏
            path: '/enterpriseScreen',
            name: 'enterpriseScreen',
            component: () => import('@/views/enterpriseScreen/index.vue'),
        }, {
            // 企业大屏-济宁
            path: '/enterpriseScreen_JN',
            name: 'enterpriseScreen_JN',
            component: () => import('@/views/enterpriseScreen_JN/index_new.vue'),
        }, {
            // 企业大屏 菏泽
            path: '/enterpriseScreen_hz',
            name: 'enterpriseScreen_hz',
            component: () => import('@/views/enterpriseScreen_hz/index.vue'),
        }, {
            // 企业大屏
            path: '/enterpriseScreen_dy',
            name: 'enterpriseScreen_dy',
            component: () => import('@/views/enterpriseScreen/index_dy.vue'),
        }, {
            // 企业大屏 宁夏
            path: '/enterpriseScreen_nx',
            name: 'enterpriseScreen_nx',
            component: () => import('@/views/enterpriseScreen/index_nx.vue'),
        }, {
            // 新物流监控
            path: '/vehicleLogisticsMonitoringTwo',
            name: 'vehicleLogisticsMonitoringTwo',
            component: () => import('@/views/productionManagement/vehicleLogisticsMonitoringTwo.vue'),
        }, {
            // 新工程生命周期
            path: '/lifeCycle',
            name: 'lifeCycle',
            component: () => import('@/views/lifeCycle/index.vue'),
        }, {
            // 云之家单点登录中转页
            path: '/sso/yunzhijia/login',
            name: 'singleSignOn',
            component: () => import('@/views/sso/yunzhijia/login.vue'),
        },
    ],

    settingsMenu: [{
        // 管理员二级树
        path: '/admin',
        name: 'admin',
        component: () => import('@/views/admin/index.vue'),
        redirect: '/admin/menu',
        children: [{
            // 二级菜单
            path: '/admin/menu',
            name: 'menu',
            component: () => import('@/views/admin/menu/index.vue'),
        }, {
            // 组织机构
            path: '/admin/org',
            name: 'org',
            component: () => import('@/views/admin/org/index.vue'),
            redirect: '/admin/org/role',
            children: [{
                // 角色管理
                path: '/admin/org/role',
                name: 'orgRole',
                component: () => import('@/views/admin/org/role/index.vue'),
            }, {
                // 部门管理
                path: '/admin/org/org',
                name: 'org',
                component: () => import('@/views/admin/org/org/index.vue'),
            }, {
                // 用户管理
                path: '/admin/org/user',
                name: 'user',
                component: () => import('@/views/admin/org/user/index.vue'),
            }, {
                // 外部用户注册
                path: '/admin/org/enroll',
                name: 'enroll',
                component: () => import('@/views/admin/org/enroll/index.vue'),
            }],
        }, {
            // 流程管理
            path: '/admin/processManage',
            name: 'processManage',
            component: () => import('@/views/admin/processManage/index.vue'),
            children: [],
        }, {
            // 全局设置
            path: '/admin/globalSettings',
            name: 'globalSettings',
            component: () => import('@/views/admin/globalSettings/index.vue'),
            children: [{
                path: '/admin/globalSettings/timeSetting',
                name: 'timeSetting',
                component: () => import('@/views/admin/globalSettings/timeSetting.vue'),
            }],
        }, {
            // 租户管控设置
            path: '/admin/tenantControlSettings',
            name: 'tenantControlSettings',
            component: () => import('@/views/admin/tenantControlSettings/index.vue'),
        }, {
            // 数据字典
            path: '/admin/dataDictionary',
            name: 'dataDictionary',
            component: () => import('@/views/admin/dataDictionary/index.vue'),
            children: [{
                // 产品标号
                path: '/admin/dataDictionary/productLabel',
                name: 'productLabel',
                component: () => import('@/views/admin/dataDictionary/productLabel_AdditionalModels.vue'),
            }, {
                // 附加型号
                path: '/admin/dataDictionary/additionalModels',
                name: 'additionalModels',
                component: () => import('@/views/admin/dataDictionary/productLabel_AdditionalModels.vue'),
            }],
        }],
    }, {
        path: '/error',
        component: () => import('@/views/default/error.vue'),
    }],
};

export default menuJson;
