/*
 * @Author: DY
 * @Date: 2020-08-15 14:27:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-22 17:40:53
 * @Describe: 
 */
// 流程场景
export default {
    install(Vue) {
        Vue.prototype.$WFShowSceneLayer = function (businessInstanceId, sceneCode, tenant_id) {
            this.$toast({
                width: '90%',
                t_url: 'businessInstance/index',
                extr: {
                    code: {
                        businessInstanceUrl: `/simpleflow/scene/${businessInstanceId}/${sceneCode}`,
                        businessInstanceId: businessInstanceId,
                        FormUseInJyFlow: true,
                        tenant_id: tenant_id,
                    },
                },
                viewPosition: 'components',
            });
        };
    },
};