import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import { eventBus } from '@/common/eventBus.ts';
import 'element-ui/lib/theme-chalk/index.css';
import '@/services/api';
import './promission';
import '@/styles/global.styl';
import plugins from '@/plugins/plugins';
import store from '@/store';
import Router from 'vue-router';
import echarts from 'echarts';
import websdk from 'easemob-websdk';
import webimconfig from '@/common/WebIM/webim.config.js';

Vue.prototype.$echarts = echarts;

// 环信
const WebIM = window.WebIM = websdk;
WebIM.config = webimconfig;
// 环信实例
// eslint-disable-next-line new-cap
const conn = WebIM.conn = new WebIM.connection({
    appKey: WebIM.config.appkey,
    isHttpDNS: WebIM.config.isHttpDNS,
    isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
    https: WebIM.config.https,
    url: WebIM.config.xmppURL,
    apiUrl: WebIM.config.apiURL,
    isAutoLogin: true,
    heartBeatWait: WebIM.config.heartBeatWait,
    autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
    autoReconnectInterval: WebIM.config.autoReconnectInterval,
    isStropheLog: WebIM.config.isStropheLog,
    delivery: WebIM.config.delivery,
});
// 账号密码登录配置
const optionsIm = {
    apiUrl: WebIM.config.apiURL,
    user: '', // 换成自己申请的账号就行，密码也需要换  
    pwd: '',
    appKey: WebIM.config.appkey,
    success: function (user) {
        localStorage.setItem('im_userInfo', JSON.stringify(user));
        console.log(user, '登陆成功');
    },
    error: function (err) {
        console.log(err);
    },
};
const userData = localStorage.getItem('userDataDetail') ? JSON.parse(localStorage.getItem('userDataDetail')) : null;
if (userData) {
    optionsIm.user = userData.AccId;
    optionsIm.pwd = userData.Token;
    // optionsIm.Uid = userData.Uid;
}

Vue.prototype.$WebIM = WebIM;
Vue.prototype.$imconn = conn;
Vue.prototype.$imoption = optionsIm;

// 调用jquery
import $ from 'jquery';
window.jQuery = $;
Vue.prototype.$ = $;

Vue.prototype.gitTaskListUpdate = function () {
    eventBus.$emit('gitTaskListUpdate');
};

// 图片拖拽验证
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

// 全局弹出层插件
import layer from './components/layer/index';
Vue.use(layer);

// 后台配置调用弹出层
import YWShowLayer from './assets/js/tableJS/YWShowLayer';
Vue.use(YWShowLayer);

// 核算/核对/等专用弹出层
import HsAndHdLayer from './assets/js/tableJS/HsAndHdLayer';
Vue.use(HsAndHdLayer);

// 后台配置调用多表单弹出层
import YWShowMultipleForms from './assets/js/tableJS/YWShowMultipleForms';
Vue.use(YWShowMultipleForms);

// 流程在办
import WFShowPhaseLayer from './assets/js/tableJS/WFShowPhaseLayer';
Vue.use(WFShowPhaseLayer);

// 流程场景
import WFShowSceneLayer from './assets/js/tableJS/WFShowSceneLayer';
Vue.use(WFShowSceneLayer);

// 流程启动时
import WFShowStartLayer from './assets/js/tableJS/WFShowStartLayer';
Vue.use(WFShowStartLayer);

// 字符串取值
import queryString from './assets/js/queryString/index';
Vue.use(queryString);
import queryStr from './assets/js/queryString/QueryStr';
Request.QueryString = queryStr;

// 全局挂载方法
import globalMountFun from './assets/js/currencyFun/globalMountFun.js';
Vue.use(globalMountFun);

// 文件服务地址
import file from '@/constants/File.constant';
Vue.prototype.FILE = file;

// 动态路由多次点击同一个菜单报错
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

// 阿里矢量图标字体引入
import './assets/fonts/icon_font/iconfont.css';
import './assets/fonts/icon_font/iconfont.js';

// 打印
import PrintPreview from './assets/js/print/PrintPreview';
Vue.use(PrintPreview);
// 本地打印插件
import Print from 'vue-print-nb';
Vue.use(Print);

// 引入dataV
import dataV from '@jiaminghi/data-view';
Vue.use(dataV);

// 大数据量渲染表格
import UmyUi from 'umy-ui';
import 'umy-ui/lib/theme-chalk/index.css';// 引入样式
Vue.use(UmyUi);

// 全局过滤器
import * as filters from './assets/js/filters';
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

// 引入地图
import VueAMap from 'vue-amap';
VueAMap.initAMapApiLoader({
    key: 'b4007ecc143884e79eab2c515ede82cf',
    plugin: [
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Geolocation',
        'AMap.Geocoder',
        'AMap.AMapManager',
        'AMap.Marker',
        'AMap.GeometryUtil',
        'AMap.DistrictSearch',
    ],
    v: '1.4.4',
});
window._AMapSecurityConfig = {
    securityJsCode: '18c398e300fae5db28ab27861cc4c304',
};

const amapKeys = Object.keys(localStorage).filter(key => key.match(/^_AMap_/));
amapKeys.forEach(key => {
    localStorage.removeItem(key);
});
Vue.use(VueAMap);

import '@/common/font/font.css';

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(plugins);

const _vue = window.$globalHub = new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');

export default _vue;